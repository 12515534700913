import { NgModule } from '@angular/core';
import { RrsAccountModule } from '@app/custom/features/rrs-account/rrs-account.module';
import { RrsAddToWishlistModule } from '@app/custom/features/rrs-add-to-wishlist/rrs-add-to-wishlist.module';
import { RrsAsmModule } from '@app/custom/features/rrs-asm/rrs-asm.module';
import { RrsAuthModule } from '@app/custom/features/rrs-auth/rrs-auth.module';
import { RrsBazaarvoiceModule } from '@app/custom/features/rrs-bazaarvoice/rrs-bazaarvoice.module';
import { RrsBasesiteModule } from '@app/custom/features/rrs-basesite/rrs-basesite.module';
import { RrsBrandsModule } from '@app/custom/features/rrs-brands/rrs-brands.module';
import { RrsCartModule } from '@app/custom/features/rrs-cart/rrs-cart.module';
import { RrsCmsModule } from '@app/custom/features/rrs-cms/rrs-cms.module';
import { RrsContactUsModule } from '@app/custom/features/rrs-contact-us/rrs-contact-us.module';
import { RrsDynamicYieldModule } from '@app/custom/features/rrs-dynamic-yield/rrs-dynamic-yield.module';
import { RrsDynatraceModule } from '@app/custom/features/rrs-dynatrace/rrs-dynatrace.module';
import { RrsFreshChatModule } from '@app/custom/features/rrs-freshchat/rrs-freshchat.module';
import { RrsGiftCardsBalanceModule } from '@app/custom/features/rrs-gift-cards-balance/rrs-gift-cards-balance.module';
import { RrsHomepageModule } from '@app/custom/features/rrs-homepage/rrs-homepage.module';
import { RrsImpervaModule } from '@app/custom/features/rrs-imperva/rrs-imperva.module';
import { RrsKlarnaBannerModule } from '@app/custom/features/rrs-klarna-banner/rrs-klarna-banner.module';
import { RrsLoqateModule } from '@app/custom/features/rrs-loqate/rrs-loqate.module';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';
import { RrsOnetrustModule } from '@app/custom/features/rrs-onetrust/rrs-onetrust.module';
import { RrsOrderStatusModule } from '@app/custom/features/rrs-order-status/rrs-order-status.module';
import { RrsOrderModule } from '@app/custom/features/rrs-order/rrs-order.module';
import { RrsPaginationModule } from '@app/custom/features/rrs-pagination/rrs-pagination.module';
import { RrsPayPalModule } from '@app/custom/features/rrs-pay-pal/rrs-pay-pal.module';
import { RrsPowerReviewsModule } from '@app/custom/features/rrs-power-reviews/rrs-power-reviews.module';
import { RrsProductCardModule } from '@app/custom/features/rrs-product-card/rrs-product-card.module';
import { RrsProductDetailsModule } from '@app/custom/features/rrs-product-details/rrs-product-details.module';
import { RrsProductListingFeatureModule } from '@app/custom/features/rrs-product-listing/rrs-product-listing-feature.module';
import { RrsResetPasswordModule } from '@app/custom/features/rrs-reset-password/rrs-reset-password.module';
import { RrsSavedCartModule } from '@app/custom/features/rrs-saved-cart/rrs-saved-cart.module';
import { RrsSetPasswordModule } from '@app/custom/features/rrs-set-password/rrs-set-password.module';
import { RrsSearchModule } from '@app/custom/features/rrs-search/rrs-search.module';
import { RrsStarRatingModule } from '@app/custom/features/rrs-star-rating/rrs-star-rating.module';
import { RrsStoreFinderModule } from '@app/custom/features/rrs-storefinder/rrs-storefinder.module';
import { RrsStorefrontModule } from '@app/custom/features/rrs-storefront/rrs-storefront.module';
import { RrsUserIdModule } from '@app/custom/features/rrs-user-id/rrs-user-id.module';
import { RrsAdobeExperiencePlatform } from '@app/custom/features/rrs-tms/rrs-adobe-experience/rrs-adobe-experience-platform.module';
import { RrsSeoModule } from '@app/custom/features/seo/rrs-seo.module';
import { RrsErrorHandlersModule } from '@app/shared/errorHandlers/rrs-error-handlers.module';
import { RrsRoutingModule } from '@app/custom/features/rrs-routing/rrs-routing.module';
import { RrsShopLookModule } from '@app/custom/features/rrs-shop-look/rrs-shop-look.module';
import { RrsUsablenetModule } from '@app/custom/features/rrs-usablenet/rrs-usablenet.module';

@NgModule({
  exports: [RrsStorefrontModule],
  imports: [
    RrsAccountModule,
    RrsAddToWishlistModule,
    RrsAdobeExperiencePlatform,
    RrsAsmModule,
    RrsAuthModule,
    RrsBazaarvoiceModule,
    RrsBasesiteModule,
    RrsBrandsModule,
    RrsCartModule,
    RrsCmsModule,
    RrsContactUsModule,
    RrsDynamicYieldModule,
    RrsDynatraceModule,
    RrsFreshChatModule,
    RrsGiftCardsBalanceModule,
    RrsHomepageModule,
    RrsKlarnaBannerModule,
    RrsLoqateModule,
    RrsOnetrustModule,
    RrsOrderStatusModule,
    RrsPaginationModule,
    RrsProductCardModule,
    RrsProductDetailsModule,
    RrsProductListingFeatureModule,
    RrsResetPasswordModule,
    RrsSavedCartModule,
    RrsSearchModule,
    RrsSetPasswordModule,
    RrsStarRatingModule,
    RrsStoreFinderModule,
    RrsStorefrontModule,
    RrsUserIdModule,
    RrsOrderModule,
    RrsPowerReviewsModule,
    RrsPayPalModule,
    RrsSeoModule,
    RrsErrorHandlersModule,
    RrsImpervaModule,
    RrsMediaModule,
    RrsRoutingModule,
    RrsShopLookModule,
    RrsUsablenetModule,
  ],
})
export class CustomFeaturesModule {}
