<div
  *ngIf="headerUi.isBannerShow | async"
  class="row align-items-center header-banner-top"
  [ngStyle]="{
    'background-color':
      banner.background_text_color.background_color || '#e5ecea'
  }">
  <ng-container *ngFor="let item of banner?.messages_ctas">
    <a
      [attr.title]="item.url_link.text"
      [routerLink]="item.url_link.url"
      class="text-center text-truncate text-uppercase col-md"
      [ngStyle]="{
        'background-color':
          banner.background_text_color.background_color || '#e5ecea',
        color: banner.background_text_color.text_color || '#757575'
      }">
      {{ item.url_link.text }}
    </a>
  </ng-container>
</div>
