export const environment = {
  production: true,
  contentstackAccessToken: process.env.CONTENTSTACK_ACCESS_TOKEN as string,
  contentstackApiKey: 'blt36800ea5df5ec785',
  contentstackEnvironment: process.env.CONTENTSTACK_ENVIRONMENT as string,
  contentstackUrl: 'https://cdn.contentstack.io/v3',
  algolia: {
    apiKey: '7CUHTHVKMM',
    appId: 'f323cc403c5a9ecb62e719129536b485',
    indexName: 'dev1_rackroom_products_v1',
  },
  googleMaps: {
    baseUrl: 'https://www.google.com/maps/embed/v1',
    apiKey: 'AIzaSyAsN6I9nnlt6rWrV0qmxUgsuehLK9aSPWs',
  },
  geolocationApiBaseUrl: 'https://us-central1-sapcx-lle.cloudfunctions.net',
  occBaseUrl: process.env.OCC_BASE_URL as string,
  i18n: {
    backend: {
      loadPath:
        'https://dev2.storefront.rackroomshoes.com/assets/i18n/{{lng}}/{{ns}}.json',
    },
  },
  loqateUrl:
    'https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws',
  loqateApiKey: 'ZM99-RX17-CZ49-XT55',
  onetrustScript: '8b99c7bb-b82e-4c71-b09b-7112408cc5c5-test',
  impervaBypassHeaderValue: process.env.IMPERVA_BYPASS_HEADER_VALUE as string,
  cyberSource: {
    orgId: '1snn5n9w',
    merchantId: 'rackroom',
    flexMicroformScriptUrl:
      'https://testflex.cybersource.com/microform/bundle/v1/flex-microform.min.js',
  },
  dynamicYield: {
    enabled: true,
    siteId: '8782408',
  },
  dynatrace: {
    oneAgentScriptUrl:
      'https://js-cdn.dynatrace.com/jstag/17052aca8bb/bf84101mwd/c60573a44990fde3_complete.js',
  },
  powerReviews: {
    apiKey: '0e4e16bb-ea96-49c7-a836-ce2fedeb887f',
    merchantId: '858690287',
    merchantGroupId: '671268024',
  },
  adobeLaunch: {
    scriptUrl:
      'https://assets.adobedtm.com/d221dcac2966/e8b5cb9cfd29/launch-581ddbed921b-development.min.js',
  },
  freshChat: {
    widgetUuid: '0b35c983-254a-4706-a5ce-2b9be0ed892c',
  },
};
