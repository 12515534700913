import { Injectable } from '@angular/core';
import { ScriptLoader, ScriptPlacement } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class RrsUsablenetService {
  constructor(protected scriptLoader: ScriptLoader) {}

  init(): void {
    this.scriptLoader.embedScript({
      src: `https://a42cdn.usablenet.com/a42/rackroomshoes/default/prod/cs-text-start`,
      params: undefined,
      attributes: {
        id: 'usntA42start',
        'data-rapid': 'true',
      },
      placement: ScriptPlacement.HEAD,
    });
  }
}
