import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RrsUsablenetService } from '@app/custom/features/rrs-usablenet/rrs-usablenet.service';
export function initUsablenet(injector: Injector): () => void {
  const result = (): void => {
    const service = injector.get(RrsUsablenetService);
    service.init();
  };
  return result;
}
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initUsablenet,
      deps: [Injector],
      multi: true,
    },
  ],
})
export class RrsUsablenetModule {}
