<a
  tabindex="0"
  href="#"
  onclick="return enableUsableNetAssistive()"
  class="UsableNetAssistive px-2 usableNetAssistive"
  >Enable Accessibility</a
>
<ng-template [cxOutlet]="StorefrontOutlets.STOREFRONT" cxPageTemplateStyle>
  <ng-template cxOutlet="cx-header">
    <header cxSkipLink="cx-header" class="storefront-header">
      <cx-page-layout section="header"></cx-page-layout>
      <rrs-global-message
        aria-atomic="true"
        aria-live="assertive"></rrs-global-message>
    </header>
  </ng-template>

  <main
    cxSkipLink="cx-main"
    class="storefront-main"
    [class.storefront-main__asm]="isAsmEnabled"
    [class.storefront-main__asm-opened]="isAsmEnabled && (isAsmOpened$ | async)"
    [class.storefront-main__checkout]="isCheckout$ | async">
    <cx-page-slot position="header_banner_slot"></cx-page-slot>
    <router-outlet></router-outlet>
  </main>

  <ng-template cxOutlet="cx-footer">
    <footer cxSkipLink="cx-footer" class="storefront-footer">
      <rrs-end-of-content></rrs-end-of-content>
      <cx-page-layout section="footer"></cx-page-layout>
    </footer>
  </ng-template>

  <rrs-scroll-to-top></rrs-scroll-to-top>
</ng-template>
